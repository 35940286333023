// images
import TopBackground from '../images/branches/turkey/header.jpg';
import kumanAri from '../images/branches/turkey/kuman.jpg';
import cansuOzturk from '../images/branches/turkey/cansu.jpg';
import doneKandemir from '../images/branches/turkey/done.jpg';
import isikMilliturk from '../images/branches/turkey/isik.jpg';
import dogukanUnay from '../images/branches/turkey/dogukan.jpg';

export const pageTexts = (t) => {
  return {
    topInfo: {
      title: {
        text: t('branches.turkey.topTitle'),
        colorClass: 'colorLight',
      },
      bottomDiagonal: true,
      opacity: true,
      image: {
        src: TopBackground,
        alt: t('branches.turkey.imgAlt'),
        smallHeight: true,
      },
    },
    ourMission: {
      title: {
        text: t('branches.turkey.ourMission'),
        colorClass: 'colorDark',
        extraClass: 'font40',
      },
      text: {
        text: t('branches.turkey.missingTransparency'),
        colorClass: 'colorDark',
        textType: 'paragraph',
      },
      separator: true,
      extraClass: 'col-md-2 col-lg-4',
    },
    ourTeam: {
      title: {
        text: t('branches.portugal.theTeam'),
        colorClass: 'colorDark',
        extraClass: 'font40',
      },
      separator: true,
      extraClass: 'col-md-2 col-lg-4',
    },
    whereWeAre: {
      title: {
        text: t('branches.turkey.whereWeAre'),
        colorClass: 'colorDark',
        extraClass: 'font40',
      },
      companyName: t('branches.turkey.companyName'),
      street: t('branches.turkey.address'),
      separator: true,
      extraClass: 'col-md-2 col-lg-4',
    },
    ourContact: {
      title: {
        text: t('branches.turkey.contact.title'),
        colorClass: 'colorDark',
        extraClass: 'font40',
      },
      email: t('branches.turkey.contact.email'),
      emailLink: t('branches.turkey.contact.emailLink'),
      separator: true,
      extraClass: 'col-md-2 col-lg-4',
    },
    theTeam: [
      {
        img: {
          src: kumanAri,
          alt: t('branches.turkey.kumanAriAlt'),
          extraClass: 'w-100',
        },
        separator: true,
        extraClass: 'col-lg-4 col-xl-3',
        text: {
          name: t('branches.turkey.kumanAri'),
          position: t('branches.turkey.kumanAriTitle'),
        },
      },
      {
        img: {
          src: cansuOzturk,
          alt: t('branches.turkey.cansuOzturkAlt'),
          extraClass: 'w-100',
        },
        separator: true,
        extraClass: 'col-lg-4 col-xl-3',
        text: {
          name: t('branches.turkey.cansuOzturk'),
          position: t('branches.turkey.cansuOzturkTitle'),
        },
      },
      {
        img: {
          src: doneKandemir,
          alt: t('branches.turkey.doneKandemirAlt'),
          extraClass: 'w-100',
        },
        separator: true,
        extraClass: 'col-lg-4 col-xl-3',
        text: {
          name: t('branches.turkey.doneKandemir'),
          position: t('branches.turkey.doneKandemirTitle'),
        },
      },
      {
        img: {
          src: isikMilliturk,
          alt: t('branches.turkey.isikMilliturkAlt'),
          extraClass: 'w-100',
        },
        separator: true,
        extraClass: 'col-lg-4 col-xl-3',
        text: {
          name: t('branches.turkey.isikMilliturk'),
          position: t('branches.turkey.isikMilliturkTitle'),
        },
      },
      {
        img: {
          src: dogukanUnay,
          alt: t('branches.turkey.dogukanUnayAlt'),
          extraClass: 'w-100',
        },
        separator: true,
        extraClass: 'col-lg-4 col-xl-3',
        text: {
          name: t('branches.turkey.dogukanUnay'),
          position: t('branches.turkey.dogukanUnayTitle'),
        },
      },
    ],
  };
};
